<template>
  <div class="login-page">
    <v-container>

      <v-overlay :value="loading" color="white" opacity="1">
        <v-progress-circular color="gifty" indeterminate size="50"/>
      </v-overlay>

      <v-row v-if="!canPayment" align="center" justify="center" class="mt-16">
        <v-col cols="12" lg="7">

          <v-card class="rounded" flat>
            <v-card-text class="text-center pa-6 pa-lg-12">
              <div class="text-center mb-5">
                <img alt="" src="../../assets/logo2.png"/>
              </div>
              <v-icon color="gifty" size="80">mdi-alert-circle</v-icon>
              <h2 class="mt-2 mb-3 grey--text text--darken-4">Important !</h2>
              <p class="grey--text text--darken-2">
                Le montant
                <span class="gifty--text font-weight-medium f-17">
                    {{ CurrencyFormatting(order.total) }} DZD
                  </span>
                est hors des limites autorisées.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else align="center" justify="center" class="mt-16">
        <v-col cols="12" lg="7">
          <div v-if="error">
            <v-card class="rounded" flat>
              <v-card-text class="text-center pa-6 pa-lg-12">
                <div class="text-center mb-5">
                  <img alt="" src="../../assets/logo2.png"/>
                </div>
                <img alt="" src="@/assets/404.svg" width="200"/>
                <h2 class="mt-5 mb-3 grey--text text--darken-4">Page not found !!</h2>
                <p class="grey--text text--darken-2">
                  Nous n'avons pas trouvé la page que vous recherchez
                </p>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>
            <v-card class="rounded" flat>

              <v-card-text class="pa-6 pa-lg-12" v-if="order">

                <div class="text-center">
                  <img alt="" src="../../assets/logo2.png"/>
                </div>

                <div class="text-center">
                  <h2 class="mt-5 mb-3 grey--text text--darken-4"> Detail de Paiement</h2>
                  <p class="mb-5 grey--text text--darken-2">
                    Veuillez vérifier les détails ci-dessous pour plus d'informations :
                  </p>
                </div>


                <v-simple-table class="table-border rounded">
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td class="font-weight-medium">Nom de client</td>
                      <td>{{ order.user.name }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-medium">Téléphone</td>
                      <td>{{ order.user.phone }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-medium">Email</td>
                      <td>{{ order.user.email }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-medium">Carte cadeau</td>
                      <td class="font-weight-medium">
                        {{ order.service.name }}
                      </td>
                    </tr>

                    <tr>
                      <td class="font-weight-medium">Montant</td>
                      <td class="font-weight-bold">
                        {{ CurrencyFormatting(order.data.amount) }} DZD
                      </td>
                    </tr>

                    <tr v-if="order.total_promos > 0">
                      <td class="font-weight-medium">Promotion</td>
                      <td class="font-weight-bold">
                        {{ CurrencyFormatting(order.total_promos) }} DZD
                      </td>
                    </tr>

                    <tr v-if="order.fees.length > 0">

                      <td class="font-weight-medium py-5">
                        Frais Service
                      </td>

                      <td>
                        <div v-for="fee in order.fees" :key="fee.name">
                          <div class="d-flex align-center">
                            <div class="font-weight-medium">{{ fee.name }}</div>
                            <div class="font-weight-bold ml-2">
                              {{ CurrencyFormatting(fee.value) }} DZD
                            </div>
                          </div>
                        </div>
                      </td>

                    </tr>

                    <tr>
                      <td class="font-weight-medium">
                        <div class="my-3">
                          Mode de paiement
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-center my-3">
                          <v-img :src="require('@/assets/carte_CIB.jpg')"
                                 class="mr-2"
                                 contain
                                 max-width="60"
                          />
                          <span class="font-weight-bold"> CIB/Edahabia</span>
                        </div>
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>


                <div class="mb-5">

                  <v-alert color="gifty" text class="mt-5">
                    <h2 class="text-center"> Total : {{ CurrencyFormatting(order.total) }} DZD</h2>
                  </v-alert>

                  <Recaptcha @verify="recaptchaVerified($event)"/>

                  <v-checkbox v-model="acceptTerms" color="gifty">
                    <template v-slot:label>
                      <div>
                        J'accepte les
                        <router-link
                            class="gifty--text font-weight-medium text-decoration-none"
                            :to="{ name: 'e_payments.terms' }" target="_blank">
                          Condition général d'utilisation
                        </router-link>
                      </div>
                    </template>
                  </v-checkbox>


                  <v-btn :disabled="!(acceptTerms && recaptcha_verified && payment_mode)"
                         :loading="btnLoading"
                         block
                         color="gifty white--text"
                         depressed
                         large
                         @click="pay">
                    Confirmer
                    <v-icon right dark>mdi-check-circle-outline</v-icon>
                  </v-btn>
                </div>

                <v-layout align-center justify-center>
                  <v-flex shrink>
                    <v-img
                        :src="require('@/assets/green_number.png')"
                        class="mt-5 pointer"
                        width="200"
                        @click="call()"
                    />
                  </v-flex>
                </v-layout>

              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import Recaptcha from "../gifty/services/Recaptcha.vue";

export default {
  components: {Recaptcha},
  data() {
    return {
      loading: true,
      btnLoading: false,
      error: "",
      order: null,
      form: {
        recaptcha_token: "",
      },
      recaptcha_verified: false,
      acceptTerms: false,
      canPayment: true,
      payment_mode: "cib",
    };
  },
  methods: {
    fectchData() {
      axios
          .get(
              process.env.VUE_APP_BASE_URL +
              "v1/e-payments/" +
              this.$route.params.token
          )
          .then((res) => {
            this.order = res.data.data;
            this.canPayment = res.data.canPayment;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.error = "Une erreur s'est produite veuillez corriger.";
          });
    },
    recaptchaVerified(token) {
      this.form.recaptcha_token = token;
      this.recaptcha_verified = true;
    },
    pay() {
      this.btnLoading = true;
      axios
          .post(
              process.env.VUE_APP_BASE_URL +
              "v1/e-payments/register/" +
              this.$route.params.token,
              this.form
          )
          .then((res) => {
            this.btnLoading = false;
            this.$successMessage = "Opération terminée avec succès";
            window.location = res.data.data.redirect_url;
          })
          .catch((err) => {
            this.btnLoading = false;
            if (err.response && err.response.data && err.response.data.message) {
              this.$errorMessage = err.response.data.message;
            }
          });
    },
    call() {
      window.open("tel:3020", "_self");
    },
  },
  mounted() {
    this.fectchData();
  },
};
</script>

<style>
</style>